export enum AnalyticIDs {
  ScreenSize = "screen_size",
  SignupSelectCountryShowpage = "signup_select_country_showpage",
  SignupShowpage = "signup_showpage",
  SignupAgeConfirmationShowpage = "signup_age_confirmation_showpage",
  TipsLandingShowPage = "tips_landing_showpage",
  CustomTipsLandingShowPage = "custom_tips_landing_showpage",
  MysteryBoxLandingShowPage = "mysterybox_landing_showpage",
  VotesLandingShowPage = "votes_landing_showpage",
  LandingShowPage = "landing_showpage",
  ErrorShowPage = "error_showpage",
  LandingUnlockContentClickButton = "landing_unlock_content_clk_btn",
  LandingUnlockChatClickButton = "landing_unlock_chat_clk_btn",
  LandingUnlockFanClickButton = "landing_unlock_fan_clk_btn",
  SignUpOnboardingAge18ClickButton = "signup_onboarding_age_18_clk_btn",
  SignUpOnboardingCountryNextClickButton = "signup_onboarding_country_next_clk_btn",
  SignUpSignUpWithFacebookClickButton = "signup_signup_with_facebook_clk_btn",
  SignUpSignUpWithEmailClickButton = "signup_signup_with_email_clk_btn",
  SignUpSignUpWithGoogleClickButton = "signup_signup_with_google_clk_btn",
  SignUpSuccessShowPage = "signup_success_showpage",
  VerificationSuccessShowPage = "verification_success_showpage",
  SignInSignInWithFacebookClickButton = "signin_signin_with_facebook_clk_btn",
  SignInSignInWithGoogleClickButton = "signin_signin_with_google_clk_btn",
  SignInForgetClickButton = "signin_forget_clk_btn",
  SignInClickButton = "signin_clk_btn",
  VoteClick = "vote_click",
  VotePaid100Click = "vote_paid_100_click",
  VotePaid500Click = "vote_paid_500_click",
  VotePaid5000Click = "vote_paid_5000_click",
  VotePaidSuccessShowPopup = "vote_paid_sucess_showpopup",
  ProfileChatNowClickButton = "profile_chatnow_clk_btn",
  ProfileTabMysteryBoxesUnlockClickButton = "profile_tab_mysteryboxes_unlock_clk_btn",
  ProfileTabMysteryBoxesBuyClickButton = "profile_tab_mysteryboxes_buy_clk_btn",
  MessengerClickChat = "messenger_clk_chat",
  MessengerChatSentClickButton = "messenger_chat_sent_clk_btn",

  MessengerChatMysteryBoxUnpackShow = "messenger_chat_mysterybox_unpack_show",
  MessengerChatMysteryBoxUnpackClickButton = "messenger_chat_mysterybox_unpack_clk_btn",
  MessengerChatMysteryBoxMediaShow = "messenger_chat_mysterybox_media_show",
  MessengerChatUserMessageRead = "messenger_chat_user_msg_read",

  ChatSignUpShowPage = "chat_signup_showpage",
  ChatSignUpClickButton = "chat_signup_clck_btn",
  FollowProfileSignUpShowPage = "follow_profile_signup_showpage",
  FollowFeedSignUpShowPage = "follow_feed_signup_showpage",
  FollowFeedSignUpClickButton = "follow_feed_signup_clck_btn",
  VoteFeedSignUpShowPage = "vote_feed_signup_showpage",
  RedAlertSignUpShowPage = "red_alert_signup_showpage",
  RedAlertSignUpClickButton = "red_alert_signup_clck_btn",
  CommentSignUpShowPage = "comment_signup_showpage",
  JoinChallengeSignUpShowPage = "join_challenge_signup_showpage",
  VideoPlayerTimeoutSignUpShowPage = "video_player_timeout_signup_showpage",
  GetForFreeHotContentSignUpShowPage = "get_for_free_hot_content_sign_up_show_page",

  AndroidOnboardingPushEnableShow = "android_onboarding_push_enable_show",
  AndroidOnboardingAllowPush = "android_onboarding_allow_push",
  AndroidOnboardingBlockPush = "android_onboarding_block_push",
  AndroidOldusersPushEnableShow = "android_oldusers_push_enable_show",
  AndroidOldusersAllowPush = "android_oldusers_allow_push",
  AndroidOldusersBlockPush = "android_oldusers_block_push",

  IOSUserPushEnableShow = "ios_user_push_enable_show",
  IOSUserAllowPush = "ios_user_allow_push",
  IOSUserBlockPush = "ios_user_block_push",
  IOSOnboardingHomeScreenPopupShow = "ios_onboarding_home_screen_popup_show",
  IOSOnboardingHomeScreenPopupClose = "ios_onboarding_home_screen_popup_close",
  IOSGetAppPopupShow = "ios_get_app_popup_show",
  IOSGetAppPopupClick = "ios_get_app_popup_clck",
  IOSGetAppPopupClose = "ios_get_app_popup_close",

  MessengerChatPaidTips200ClickButton = "messenger_chat_paid_tips_200_clck_btn",
  MessengerChatPaidTips500ClickButton = "messenger_chat_paid_tips_500_clck_btn",
  MessengerChatPaidTips1000ClickButton = "messenger_chat_paid_tips_1000_clck_btn",
  MessengerChatPaidTips5000ClickButton = "messenger_chat_paid_tips_5000_clck_btn",
  MessengerChatPaidTips10000ClickButton = "messenger_chat_paid_tips_10000_clck_btn",

  MessengerChatLandingPaidTips200ClickButton = "messenger_chat_landing_paid_tips_200_clck_btn",
  MessengerChatLandingPaidTips500ClickButton = "messenger_chat_landing_paid_tips_500_clck_btn",
  MessengerChatLandingPaidTips1000ClickButton = "messenger_chat_landing_paid_tips_1000_clck_btn",
  MessengerChatLandingPaidTips5000ClickButton = "messenger_chat_landing_paid_tips_5000_clck_btn",
  MessengerChatLandingPaidTips10000ClickButton = "messenger_chat_landing_paid_tips_10000_clck_btn",

  MessengerPaidTipsLandingChatWithMeClickButton = "messenger_paid_tips_landing_chat_with_me_clck_btn",

  MessengerCreateTipsPopup200Click = "messenger_create_tips_popup_200_clck",
  MessengerCreateTipsPopup500Click = "messenger_create_tips_popup_500_clck",
  MessengerCreateTipsPopup1000Click = "messenger_create_tips_popup_1000_clck",
  MessengerCreateTipsPopup5000Click = "messenger_create_tips_popup_5000_clck",
  MessengerCreateTipsPopup10000Click = "messenger_create_tips_popup_10000_clck",

  MessengerCreateTipsPopupSendTipClickButton = "messenger_create_tips_popup_send_tip_clck_btn",

  MessengerCreateTipsPopupShareClickButton = "messenger_create_tips_popup_share_clck_btn",

  MessengerPaidMysteryBoxLandingChatWithMeClickButton = "messenger_paid_mysterybox_landing_chat_with_me_clck_btn",
  MessengerPaidLandingMysteryBox100ClickButton = "messenger_paid_landing_mysterybox_100_clk_btn",
  MessengerPaidLandingMysteryBox500ClickButton = "messenger_paid_landing_mysterybox_500_clk_btn",
  MessengerPaidLandingMysteryBox5000ClickButton = "messenger_paid_landing_mysterybox_5000_clk_btn",
  MessengerPaidLandingMysteryBox10000ClickButton = "messenger_paid_landing_mysterybox_10000_clk_btn",

  MessengerChatPaidMysteryBox100ClickButton = "messenger_chat_paid_mysterybox_100_clk_btn",
  MessengerChatPaidMysteryBox500ClickButton = "messenger_chat_paid_mysterybox_500_clk_btn",
  MessengerChatPaidMysteryBox5000ClickButton = "messenger_chat_paid_mysterybox_5000_clk_btn",
  MessengerChatPaidMysteryBox10000ClickButton = "messenger_chat_paid_mysterybox_10000_clk_btn",

  MessengerMysteryBoxPopup100SendClick = "messenger_mysterybox_popup_100_send_clk",
  MessengerMysteryBoxPopup500SendClick = "messenger_mysterybox_popup_500_send_clk",
  MessengerMysteryBoxPopup5000SendClick = "messenger_mysterybox_popup_5000_send_clk",
  MessengerMysteryBoxPopup10000SendClick = "messenger_mysterybox_popup_10000_send_clk",

  MessengerMysteryBoxPopup100ShareClick = "messenger_mysterybox_popup_100_share_clk",
  MessengerMysteryBoxPopup500ShareClick = "messenger_mysterybox_popup_500_share_clk",
  MessengerMysteryBoxPopup5000ShareClick = "messenger_mysterybox_popup_5000_share_clk",
  MessengerMysteryBoxPopup10000ShareClick = "messenger_mysterybox_popup_10000_share_clk",

  MessengerMysteryBoxPopup100Click = "messenger_mysterybox_popup_100_clck",
  MessengerMysteryBoxPopup500Click = "messenger_mysterybox_popup_500_clck",
  MessengerMysteryBoxPopup5000Click = "messenger_mysterybox_popup_5000_clck",
  MessengerMysteryBoxPopup10000Click = "messenger_mysterybox_popup_10000_clck",

  ProfileTabMysteryBox100ShareClick = "profile_tab_mysterybox_100_share_clk",
  ProfileTabMysteryBox500ShareClick = "profile_tab_mysterybox_500_share_clk",
  ProfileTabMysteryBox5000ShareClick = "profile_tab_mysterybox_5000_share_clk",
  ProfileTabMysteryBox10000ShareClick = "profile_tab_mysterybox_10000_share_clk",

  MessengerChatTipsPopupClick = "messenger_chat_tips_popup_clk",
  MessengerChatMysteryBoxPopupClick = "messenger_chat_mysterybox_popup_clk",
  MessengerChatRealGiftPopupClick = "messenger_chat_real_gift_popup_clk",
  MessengerChatOnlineGiftPopupClick = "messenger_chat_online_gift_popup_clk",
  MessengerChatScriptsPopupClick = "messenger_chat_scripts_popup_clk",
  MessengerChatVotesPopupClick = "messenger_chat_votes_popup_clk",

  MessengerChatVotesPopupXSSendClickButton = "messenger_chat_votes_popup_xs_send_clk_btn",
  MessengerChatVotesPopupSSendClickButton = "messenger_chat_votes_popup_s_send_clk_btn",
  MessengerChatVotesPopupMSendClickButton = "messenger_chat_votes_popup_m_send_clk_btn",
  MessengerChatVotesPopupLSendClickButton = "messenger_chat_votes_popup_l_send_clk_btn",
  MessengerChatVotesPopupXLSendClickButton = "messenger_chat_votes_popup_xl_send_clk_btn",
  MessengerChatVotesPopupXXLSendClickButton = "messenger_chat_votes_popup_xxl_send_clk_btn",

  MessengerChatVotesPopupChooseVideoClick = "messenger_chat_votes_popup_choose_video_clk",

  MessengerChatVotesPopupEditClick = "messenger_chat_votes_popup_edit_clk",
  MessengerChatVotesPopupEditCloseClick = "messenger_chat_votes_popup_edit_close_clk",

  MessengerChatVotesPopupXSShareClickButton = "messenger_chat_votes_popup_xs_share_clk_btn",
  MessengerChatVotesPopupSShareClickButton = "messenger_chat_votes_popup_s_share_clk_btn",
  MessengerChatVotesPopupMShareClickButton = "messenger_chat_votes_popup_m_share_clk_btn",
  MessengerChatVotesPopupLShareClickButton = "messenger_chat_votes_popup_l_share_clk_btn",
  MessengerChatVotesPopupXLShareClickButton = "messenger_chat_votes_popup_xl_share_clk_btn",
  MessengerChatVotesPopupXXLShareClickButton = "messenger_chat_votes_popup_xxl_share_clk_btn",

  MessengerChatVotesVoteForMeXSClickButton = "messenger_chat_votes_vote_for_me_xs_clk_btn",
  MessengerChatVotesVoteForMeSClickButton = "messenger_chat_votes_vote_for_me_s_clk_btn",
  MessengerChatVotesVoteForMeMClickButton = "messenger_chat_votes_vote_for_me_m_clk_btn",
  MessengerChatVotesVoteForMeLClickButton = "messenger_chat_votes_vote_for_me_l_clk_btn",
  MessengerChatVotesVoteForMeXLClickButton = "messenger_chat_votes_vote_for_me_xl_clk_btn",
  MessengerChatVotesVoteForMeXXLClickButton = "messenger_chat_votes_vote_for_me_xxl_clk_btn",

  MessengerLandingVotesVoteForMeXSClickButton = "messenger_landing_votes_vote_for_me_xs_clk_btn",
  MessengerLandingVotesVoteForMeSClickButton = "messenger_landing_votes_vote_for_me_s_clk_btn",
  MessengerLandingVotesVoteForMeMClickButton = "messenger_landing_votes_vote_for_me_m_clk_btn",
  MessengerLandingVotesVoteForMeLClickButton = "messenger_landing_votes_vote_for_me_l_clk_btn",
  MessengerLandingVotesVoteForMeXLClickButton = "messenger_landing_votes_vote_for_me_xl_clk_btn",
  MessengerLandingVotesVoteForMeXXLClickButton = "messenger_landing_votes_vote_for_me_xxl_clk_btn",

  MessengerLandingVotesAvatarClick = "messenger_landing_votes_avatar_clk",

  MessengerPaidVotesLandingChatWithMeClickButton = "messenger_paid_votes_landing_chat_with_me_clck_btn",

  MessengerChatRealGiftPopupCreateClickButton = "messenger_chat_real_gift_popup_create_clk_btn",
  MessengerChatRealGiftPopupChooseEditClickButton = "messenger_chat_real_gift_popup_choose_edit_clk_btn",
  MessengerChatRealGiftPopupEditCloseButton = "messenger_chat_real_gift_popup_edit_close_clk",
  MessengerChatRealGiftPopupCreateCloseClick = "messenger_chat_real_gift_popup_create_close_clk",
  MessengerChatRealGiftPopupCreateSaveGiftClickButton = "messenger_chat_real_gift_popup_create_save_gift_clk_btn",
  MessengerChatRealGiftPopupChooseClick = "messenger_chat_real_gift_popup_choose_clk",
  MessengerChatRealGiftPopupChooseCloseClick = "messenger_chat_real_gift_popup_choose_close_clk",
  MessengerChatRealGiftDonatePopupSSendClickButton = "messenger_chat_real_gift_donate_popup_s_send_clk_btn",
  MessengerChatRealGiftDonatePopupMSendClickButton = "messenger_chat_real_gift_donate_popup_m_send_clk_btn",
  MessengerChatRealGiftDonatePopupLSendClickButton = "messenger_chat_real_gift_donate_popup_l_send_clk_btn",
  MessengerChatRealGiftDonatePopupXLSendClickButton = "messenger_chat_real_gift_donate_popup_xl_send_clk_btn",
  MessengerChatRealGiftDonatePopupXXLSendClickButton = "messenger_chat_real_gift_donate_popup_xxl_send_clk_btn",
  MessengerChatRealGiftDonatePopupSShareClickButton = "messenger_chat_real_gift_donate_popup_s_share_clk_btn",
  MessengerChatRealGiftDonatePopupMShareClickButton = "messenger_chat_real_gift_donate_popup_m_share_clk_btn",
  MessengerChatRealGiftDonatePopupLShareClickButton = "messenger_chat_real_gift_donate_popup_l_share_clk_btn",
  MessengerChatRealGiftDonatePopupXLShareClickButton = "messenger_chat_real_gift_donate_popup_xl_share_clk_btn",
  MessengerChatRealGiftDonatePopupXXLShareClickButton = "messenger_chat_real_gift_donate_popup_xxl_share_clk_btn",
  MessengerChatRealGiftDonateMeSClickButton = "messenger_chat_real_gift_donate_me_s_clk_btn",
  MessengerChatRealGiftDonateMeMClickButton = "messenger_chat_real_gift_donate_me_m_clk_btn",
  MessengerChatRealGiftDonateMeLClickButton = "messenger_chat_real_gift_donate_me_l_clk_btn",
  MessengerChatRealGiftDonateMeXLClickButton = "messenger_chat_real_gift_donate_me_xl_clk_btn",
  MessengerChatRealGiftDonateMeXXLClickButton = "messenger_chat_real_gift_donate_me_xxl_clk_btn",
  MessengerLandingRealGiftDonateMeSClickButton = "messenger_landing_real_gift_donate_me_s_clk_btn",
  MessengerLandingRealGiftDonateMeMClickButton = "messenger_landing_real_gift_donate_me_m_clk_btn",
  MessengerLandingRealGiftDonateMeLClickButton = "messenger_landing_real_gift_donate_me_l_clk_btn",
  MessengerLandingRealGiftDonateMeXLClickButton = "messenger_landing_real_gift_donate_me_xl_clk_btn",
  MessengerLandingRealGiftDonateMeXXLClickButton = "messenger_landing_real_gift_donate_me_xxl_clk_btn",
  MessengerLandingRealGiftChatWithMeClickButton = "messenger_landing_real_gift_chat_with_me_clk_btn",
  RealGiftLandingShowPage = "real_gift_landing_showpage",
  MessengerLandingRealGiftAvatarClick = "messenger_landing_real_gift_avatar_clk",

  MessengerChatOnlineGiftPopupChoose_SIZE_Click = "messenger_chat_online_gift_popup_choose_SIZE_clk",
  MessengerChatOnlineGiftPopup_SIZE_SendClickButton = "messenger_chat_online_gift_popup_SIZE_send_clk_btn",
  MessengerChatOnlineGiftPopup_SIZE_ShareClickButton = "messenger_chat_online_gift_popup_SIZE_share_clk_btn",
  MessengerChatOnlineGiftGiftMe_SIZE_ClickButton = "messenger_chat_online_gift_gift_me_SIZE_clk_btn",
  MessengerLandingOnlineGiftGiftMe_SIZE_ClickButton = "messenger_landing_online_gift_gift_me_SIZE_clk_btn",
  MessengerLandingOnlineGiftChatWithMeClickButton = "messenger_landing_online_gift_chat_with_me_clk_btn",
  MessengerLandingOnlineGiftAvatarClick = "messenger_landing_online_gift_avatar_clk",
  OnlineGiftLandingShowpage = "online_gift_landing_showpage",

  MessengerChatLinkbioRealHomeClkBtn = "messenger_chat_linkbio_real_home_clk_btn",
  MessengerChatLinkbioProfessionalSexyClkBtn = "messenger_chat_linkbio_professional_sexy_clk_btn",
  MessengerChatLinkbioFantasySetClkBtn = "messenger_chat_linkbio_fantasy_set_clk_btn",
  MessengerChatLinkbioGetForFreeClkBtn = "messenger_chat_linkbio_get_for_free_clk_btn",
  MessengerChatLinkbioBuyClkBtn = "messenger_chat_linkbio_buy_clk_btn",
  MessengerChatLinkbioUnlockClkBtn = "messenger_chat_linkbio_unlock_clk_btn",

  LogoutBlockedUserRegular = "logout_blocked_user_regular",
  LogoutDeletedUserRegular = "logout_deleted_user_regular",
  LogoutRegular = "logout_regular",
  LogoutNeedReloginEvent = "logout_need_relogin_event",
  LogoutCouldNotUpdateRefreshToken = "logout_could_not_update_refresh_token",
  LogoutAuthDeclinedNeedRelogin = "logout_auth_declined_need_relogin",
  LogoutAuthDeclinedDeletedUser = "logout_auth_declined_deleted_user",

  LoginByHashSuccess = "login_by_hash_success",
  LoginByHashFailed = "login_by_hash_failed",

  RenewalTokenByRefreshToken = "renewal_token_by_refresh_token",
  RefreshTokenSuccess = "refresh_token_success",
  RefreshTokenFailed = "refresh_token_failed",

  MessengerChatWidgetClick = "messenger_chat_widget_clk",

  MessengerChatCustomTipsPopupClick = "messenger_chat_custom_tips_popup_clk",
  MessengerChatCreateCustomTipsPopupTipMeClickButton = "messenger_chat_create_custom_tips_popup_tip_me_clk_btn",
  MessengerChatCreateCustomTipsPopupCreateClickButton = "messenger_chat_create_custom_tips_popup_create_clk_btn",
  MessengerChatCreateCustomTipsPopupCloseClick = "messenger_chat_create_custom_tips_popup_close_clk",
  MessengerChatCustomTipsPopupCloseClick = "messenger_chat_custom_tips_popup_close_clk",
  MessengerChatCustomTipsPopupSSendClickButton = "messenger_chat_custom_tips_popup_s_send_clk_btn",
  MessengerChatCustomTipsPopupMSendClickButton = "messenger_chat_custom_tips_popup_m_send_clk_btn",
  MessengerChatCustomTipsPopupLSendClickButton = "messenger_chat_custom_tips_popup_l_send_clk_btn",
  MessengerChatCustomTipsPopupXLSendClickButton = "messenger_chat_custom_tips_popup_xl_send_clk_btn",
  MessengerChatCustomTipsPopupXXLSendClickButton = "messenger_chat_custom_tips_popup_xxl_send_clk_btn",
  MessengerChatCustomTipsTipMeSClickButton = "messenger_chat_custom_tips_tip_me_s_clk_btn",
  MessengerChatCustomTipsTipMeMClickButton = "messenger_chat_custom_tips_tip_me_m_clk_btn",
  MessengerChatCustomTipsTipMeLClickButton = "messenger_chat_custom_tips_tip_me_l_clk_btn",
  MessengerChatCustomTipsTipMeXLClickButton = "messenger_chat_custom_tips_tip_me_xl_clk_btn",
  MessengerChatCustomTipsTipMeXXLClickButton = "messenger_chat_custom_tips_tip_me_xxl_clk_btn",
  MessengerChatCustomTipsUnpackClickButton = "messenger_chat_custom_tips_unpack_clk_btn",
  MessengerChatCustomTipsUnpackShow = "messenger_chat_custom_tips_unpack_show",
  MessengerChatCustomTipsUnpackMediaShow = "messenger_chat_custom_tips_unpack_media_show",
  MessengerLandingCustomTipsTipMeSClickButton = "messenger_landing_custom_tips_tip_me_s_clk_btn",
  MessengerLandingCustomTipsTipMeMClickButton = "messenger_landing_custom_tips_tip_me_m_clk_btn",
  MessengerLandingCustomTipsTipMeLClickButton = "messenger_landing_custom_tips_tip_me_l_clk_btn",
  MessengerLandingCustomTipsTipMeXLClickButton = "messenger_landing_custom_tips_tip_me_xl_clk_btn",
  MessengerLandingCustomTipsTipMeXXLClickButton = "messenger_landing_custom_tips_tip_me_xxl_clk_btn",
  MessengerLandingCustomTipsChatWithMeClickButton = "messenger_landing_custom_tips_chat_with_me_clk_btn",
  MessengerLandingCustomTipsAvatarClick = "messenger_landing_custom_tips_avatar_clk",

  MessengerChatConnectionError = "messenger_chat_connection_error",
}
