const Amplitude = {
  track: (eventType?: string, options?: any) => {
    if (eventType) {
      const userUid = localStorage.getItem("userId");

      const optionsWithUserUid = {
        ...(options ? options : {}),
        current_user_uid: userUid || "undefined",
      };

      if (window.location?.href?.includes("localhost")) {
        console.log(eventType, optionsWithUserUid);
      } else {
        // @ts-ignore
        window.amplitude?.track?.(eventType, optionsWithUserUid);
      }
    }
  },
};

export default Amplitude;
