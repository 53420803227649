import {createSelector, createSlice} from "@reduxjs/toolkit";
import {AppState} from "../store";
import {IUser} from "../interfaces/user";
import {IContentMysteryBox, IContentPrices} from "../interfaces/content";
import {authOverlaySelector} from "@app/store/slices/authOverlay";

interface IVoteDrawerStore {
  prices?: IContentPrices;
  mysteryBoxes?: IContentMysteryBox[];
  user?: Partial<IUser>;
  visible: boolean;
  contentId?: string;
}

const initialState: IVoteDrawerStore = {
  contentId: undefined,
  visible: false,
};

export const voteDrawerSlice = createSlice({
  name: "voteDrawer",
  initialState,
  reducers: {
    updateFreeDateVoteDrawer: (state, action) => {
      if (state.prices) {
        state.prices.freeVoteDate = {
          ...state.prices.freeVoteDate,
          date: action.payload.date,
        };
      }
    },
    setVoteDrawer: (state, action) => {
      state.prices = action.payload.prices;
      state.user = action.payload.user;
      state.contentId = action.payload.contentId;
    },
    setMysteryBoxes: (state, action) => {
      state.mysteryBoxes = action.payload;
    },
    setOpenVoteDrawer: (state) => {
      state.visible = true;
    },
    setCloseVoteDrawer: (state) => {
      state.visible = false;
    },
    clearVoteDrawer: () => {
      return initialState;
    },
  },
});

export const {
  updateFreeDateVoteDrawer,
  setVoteDrawer,
  setMysteryBoxes,
  setOpenVoteDrawer,
  setCloseVoteDrawer,
  clearVoteDrawer,
} = voteDrawerSlice.actions;

export const voteDrawerSelector = (state: AppState) => state.voteDrawer;

export const isVoteDrawerVisibleSelector = createSelector(
  [voteDrawerSelector, authOverlaySelector],
  (voteDrawer, authOverlay) => {
    return voteDrawer.visible && !authOverlay.visible;
  },
);

export const voteDrawerReducer = voteDrawerSlice.reducer;
