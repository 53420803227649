import {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "@app/store/hooks";
import {profileSelector, setIsAuthenticated} from "@app/store/slices/profile";

const useAuth = () => {
  const {isAuthenticated} = useAppSelector(profileSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const token = localStorage.getItem("token");
    dispatch(setIsAuthenticated(!!token));
  }, []);

  return isAuthenticated || !!localStorage.getItem("token");
};

export default useAuth;
