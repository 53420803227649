import {privateApi, publicApi} from "./api";
import {getMessaging, deleteToken} from "firebase/messaging";
import clearStorageUserData from "../utils/clearStorageUserData";
import {AuthByHashType} from "@services/type";

const authService = {
  signin: async (options: any) => {
    return await publicApi.post("/user/login", options);
  },
  signup: async (options: any) => {
    const {regPoint, ...restOptions} = options;
    const regPointQuery = regPoint ? "?reg_point=" + regPoint : "";
    localStorage.removeItem("register");
    return await privateApi.post(`/user/register${regPointQuery}`, restOptions);
  },
  signupAnonym: async (options: any) => {
    localStorage.removeItem("anonPaymentPopup");
    return await publicApi.post("/user/register/anonym", options);
  },
  signout: async () => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "User-Id": localStorage.getItem("userId"),
    };
    try {
      const response = await publicApi.post("/user/logout", {}, {headers});

      if (response.status === 202) {
        const messaging = getMessaging();
        try {
          await deleteToken(messaging);
          clearStorageUserData();
          console.log("Successfully deleted firebase token");
        } catch (fbError) {
          clearStorageUserData();
          console.error("Error deleting firebase token", fbError);
        }
      } else {
        clearStorageUserData();
        console.log("Failed to logout", response);
      }
    } catch (error) {
      clearStorageUserData();
      console.log(error);
    }
  },
  resendLink: async (email: string) => {
    return await publicApi.post("/user/confirmation/resend", {email});
  },
  resetPassword: async (options: any) => {
    return await publicApi.post("/user/reset-password", options);
  },
  changePassword: async (options: any) => {
    return await publicApi.post("/user/change-password", options);
  },
  authByHash: async (hash: string, authType: AuthByHashType) => {
    return await publicApi.get(`/auth/token/${hash}/${authType}`);
  },
  confirmOver18: async () => {
    return await publicApi.post("/user/confirmation/over18", {});
  },
  confirmPWA: async () => {
    return await privateApi.post("/user/pwa/save", {});
  },
};

export default authService;
