import axios, {AxiosRequestConfig} from "axios";
import appService, {ErrorPriority, LogType} from "./appService";
import clearStorageUserData from "../utils/clearStorageUserData";
import Amplitude from "@utils/amplitude";
import {AnalyticIDs} from "@app/store/interfaces/analytics";

export const baseURL = process.env.REACT_APP_API_URL;

export const logError = (error: any, priority?: ErrorPriority) => {
  appService
    .send({
      type: LogType.Error,
      priority,
      data: {
        message: error.response?.stack ?? error.response,
      },
    })
    .catch((err) => {
      console.log("[common]", err);
    });
};

let guestUserId = localStorage.getItem("guestUserId");

export const privateApi = axios.create({
  baseURL,
  headers: {
    Accept: "application/json, text/plain",
  },
});

export const publicApi = axios.create({
  baseURL,
});

publicApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    logError(error);

    return Promise.reject(error);
  },
);

publicApi.interceptors.request.use(
  (config) => {
    if (guestUserId) {
      config.headers["User-Id"] = guestUserId;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

privateApi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    if (guestUserId) {
      config.headers["User-Id"] = guestUserId;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

// List to hold the request queue
let refreshAndRetryQueue: any[] = [];

// Flag to prevent multiple token refresh requests
let isRefreshing = false;

const processQueue = (error: any, token = null) => {
  refreshAndRetryQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  refreshAndRetryQueue = [];
};

privateApi.interceptors.response.use(
  (response) => {
    if (response.headers["user-id"] && !guestUserId) {
      guestUserId = response.headers["user-id"];
      guestUserId && localStorage.setItem("guestUserId", guestUserId);
    }
    if (response.headers["user-country"]) {
      const userCountry = response.headers["user-country"];
      userCountry && localStorage.setItem("userCountry", userCountry);
    }
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    const isUserAuthError = error?.response?.status === 401;
    const isUserAuthDecline = error?.response?.status === 403;

    const isNeedRelogin = error?.response?.data?.detail === "Need relogin";

    if (isUserAuthDecline && isNeedRelogin) {
      Amplitude.track(AnalyticIDs.LogoutAuthDeclinedNeedRelogin);
      clearStorageUserData();
    }

    if (!isUserAuthError && originalRequest.url !== "/tech/logs") {
      logError(error);
    }

    if (
      isUserAuthError &&
      !originalRequest._retry &&
      originalRequest.url !== "/user/info"
    ) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          refreshAndRetryQueue.push({resolve, reject});
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return axios(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      Amplitude.track(AnalyticIDs.RenewalTokenByRefreshToken);

      originalRequest._retry = true;
      isRefreshing = true;

      const refreshToken = window.localStorage.getItem("refreshToken");

      return new Promise(function (resolve, reject) {
        axios
          .post(`${baseURL}/auth/token/refresh`, {refreshToken})
          .then(({data}) => {
            localStorage.setItem("token", data.token);
            localStorage.setItem("refreshToken", data.refreshToken);
            localStorage.setItem("streamToken", data.streamToken);

            Amplitude.track(AnalyticIDs.RefreshTokenSuccess);

            axios.defaults.headers.common["Authorization"] =
              "Bearer " + data.token;
            originalRequest.headers["Authorization"] = "Bearer " + data.token;

            processQueue(null, data.token);

            resolve(axios(originalRequest));
          })
          .catch((err) => {
            Amplitude.track(AnalyticIDs.RefreshTokenFailed);
            Amplitude.track(AnalyticIDs.LogoutCouldNotUpdateRefreshToken);

            clearStorageUserData();

            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    } else {
      return Promise.reject(error);
    }
  },
);
