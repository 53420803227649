import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ChannelSortBase} from "stream-chat";
import {AppState} from "../store";

interface IChatStore {
  sort: keyof ChannelSortBase;
  fillValue: string;
  isHotContentUnpackAnimationVisible: boolean;
}

const initialState: IChatStore = {
  sort: "last_message_at",
  fillValue: "",
  isHotContentUnpackAnimationVisible: false,
};

export const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setSort: (state, action: PayloadAction<keyof ChannelSortBase>) => {
      state.sort = action.payload;
    },
    fillInput: (state, action: PayloadAction<string>) => {
      state.fillValue = action.payload;
    },
    setHotContentUnpackAnimationVisible: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.isHotContentUnpackAnimationVisible = action.payload;
    },
  },
});

export const {setSort, fillInput, setHotContentUnpackAnimationVisible} =
  chatSlice.actions;

export const chatSelector = (state: AppState) => state.chat;

export const chatReducer = chatSlice.reducer;
