import {BREAKPOINTS, getWindowSizeCategory} from "@utils/getWindowSize";

export const isIOS = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();

  return /iphone|ipad|ipod/.test(userAgent);
};

export const isAndroid = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();

  return /android/.test(userAgent);
};

export const isMobile = () => {
  return isIOS() || isAndroid();
};

export const isMinWidthLg = () => {
  const windowCategory = getWindowSizeCategory();
  return (
    windowCategory === "lg" ||
    windowCategory === "xl" ||
    windowCategory === "2xl"
  );
};

export const isInstagram = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();

  return /instagram/i.test(userAgent);
};

export const isFB = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();

  return /fb|facebook/i.test(userAgent);
};

export const isTwitter = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();

  return /twitter/i.test(userAgent);
};

export const isInAppBrowser = () => {
  return isInstagram() || isFB() || isTwitter();
};

export const isPWA = () => {
  return window.matchMedia("(display-mode: standalone)").matches;
};

export const checkRuByIp = () => {
  const userCountry = localStorage.getItem("userCountry");

  return userCountry && userCountry === "RU";
};
