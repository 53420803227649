const clearStorageUserData = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("streamToken");
  localStorage.removeItem("registration-email");
  localStorage.removeItem("userId");
  localStorage.removeItem("login");
  localStorage.removeItem("register");
  localStorage.removeItem("userCountry");
  localStorage.removeItem("anonUserId");
  localStorage.removeItem("freeVotesCount");
  localStorage.removeItem("anonPaymentPopup");
  localStorage.removeItem("afterAnonAuthPage");
  localStorage.removeItem("afterHashAuthPage");
  window.location.assign(window.location.origin);
};

export default clearStorageUserData;
