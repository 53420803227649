import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppState} from "../store";
import {CountryItem} from "@components/country-autocomplete/type";

export enum AuthMethod {
  Email = "Email",
  Google = "Google",
  Facebook = "Facebook",
}

interface ISignupStore {
  country?: CountryItem | undefined;
  reffererUserUid?: string;
  authMethod?: AuthMethod;
  contentId?: string;
  reffererChallengeId?: string;
  isAgeConfirmed?: boolean;
}

const initialState: ISignupStore = {};

export const signupSlice = createSlice({
  name: "signup",
  initialState,
  reducers: {
    setAuthMethod: (state, action: PayloadAction<AuthMethod | undefined>) => {
      state.authMethod = action.payload;
    },
    setReffererUserUid: (state, action: PayloadAction<string | undefined>) => {
      state.reffererUserUid = action.payload;
    },
    setContentId: (state, action: PayloadAction<string | undefined>) => {
      state.contentId = action.payload;
    },
    setReffererChallengeId: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.reffererChallengeId = action.payload;
    },
    setCountry: (state, action: PayloadAction<CountryItem | undefined>) => {
      state.country = action.payload;
    },
    setAgeConfirmed: (state, action: PayloadAction<boolean>) => {
      state.isAgeConfirmed = action.payload;
    },
  },
});

export const {
  setAuthMethod,
  setReffererUserUid,
  setContentId,
  setReffererChallengeId,
  setCountry,
  setAgeConfirmed,
} = signupSlice.actions;

export const signupSelector = (state: AppState) => state.signup;

export const signupReducer = signupSlice.reducer;
