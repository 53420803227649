import {IUser} from "./user";

export interface IVotesItem {
  createdAt?: string;
  background: string;
  format?: string;
  recipientUid?: string;
  status?: string;
  type?: VoteSize;
  uid?: string;
  url?: string;
  price: number;
  priceRu?: number;
  votes: number;
  user?: IUser;
  title: string;
  rate?: number;
  size: VoteSize;
  description?: string;
}

export interface IVotesPriceItem {
  type: VoteSize;
  price: number;
  priceRu?: number;
  votes: number;
  imageUrl: string;
  mediaUrl?: string;
  mediaUid?: string;
  background: string;
  forShow: boolean;
  updatedDescription?: string;
}

export enum VoteSize {
  XS = "XS",
  S = "S",
  M = "M",
  L = "L",
  XL = "XL",
  XXL = "XXL",
}

export const votesMockData: IVotesItem[] = [
  {
    size: VoteSize.XS,
    price: 2.99,
    priceRu: 299,
    votes: 100,
    title: "#Cozy Home",
    rate: 45,
    description: "Description1",
    background: "linear-gradient(#FCEC21 2%, #FF8A00 122.69%)",
  },
  {
    size: VoteSize.S,
    price: 4.99,
    priceRu: 499,
    votes: 200,
    title: "#Cozy Home",
    rate: 55,
    description: "Description2",
    background: "linear-gradient(1.3deg, #FF4D00 1.8%, #FCEC21 122.69%)",
  },
  {
    size: VoteSize.M,
    price: 9.99,
    priceRu: 999,
    votes: 500,
    title: "#Cozy Home",
    rate: 65,
    description: "Description3",
    background:
      "linear-gradient(106.9deg, #FF8FED -17.57%, #FF95EE 4.77%, #FF73E0 19.9%, #FF4BA1 52.61%, #FF3093 93.65%)",
  },
  {
    size: VoteSize.L,
    price: 19.99,
    priceRu: 1999,
    votes: 1000,
    title: "#Cozy Home",
    rate: 75,
    description: "Description4",
    background:
      "linear-gradient(106.9deg, #FF00D6 -17.57%, #FF00D6 4.77%, #FF00C7 19.9%, #FF007A 52.61%, #FF007A 93.65%)",
  },
  {
    size: VoteSize.XL,
    price: 49.99,
    priceRu: 4999,
    votes: 5000,
    title: "#Cozy Home",
    rate: 85,
    description: "Description5",
    background: "linear-gradient(358.57deg, #131A98 -8.62%, #1CC6FC 113.83%)",
  },
  {
    size: VoteSize.XXL,
    price: 99.99,
    priceRu: 9999,
    votes: 15000,
    title: "#Cozy Home",
    rate: 95,
    description: "Description6",
    background: "linear-gradient(180deg, #AD44FF 10%, #52078D 100%)",
  },
];

const extendedUser: IUser = {
  uid: "01HE50RJ1T323D6DKQ6RQ8Y67E",
  name: "Bart Simpson",
  username: "bartholomew",
  fullName: "Bart Simpson",
  avatar: "https://placehold.co/80x80/skyblue/white",
  onlineStatus: {
    isOnline: true,
    status: "online",
    lastSeen: "2024-10-13 13:40:12",
  },
  email: "bart@example.com",
  description: "Just a guy.",
  birthDate: "2000-01-01",
  gender: "Male",
  country: "USA",
  language: "English",
  city: "Springfield",
  isRu: false,
  isPWA: false,
  slogan: "Don't have a cow, man!",
  status: "Active",
  isVerified: true,
  isApproved: true,
  isAnonym: false,
  notificationCount: 0,
  messagesCount: 0,
  payingMessagesCount: 0,
  regularMessagesCount: 0,
  following: true,
  challenges: {
    subscribed: ["10"],
    joined: ["5"],
  },
  counters: {
    followers: 500,
    following: 100,
    viewers: 1000,
  },
  socials: {
    twitter: "@bartsimpson",
    instagram: "@bartsimpson",
  },
  contentsAllItems: [],
  contentsAllCurrentPage: 0,
  contentsAllLastPage: false,
  mysteryItems: [],
  mysteryCurrentPage: 0,
  mysteryLastPage: false,
};

export const votesDetailMock: IVotesItem = {
  background: "linear-gradient(180deg, #AD44FF 10%, #52078D 100%)",
  size: VoteSize.XXL,
  title: "№158 #cozyhome",
  uid: "01HDRC59DGYS83QYCR767Z9KVV",
  recipientUid: "01HDGVA4EWQTTPZPNQY5P25YGJ",
  createdAt: "2023-10-27 10:51:26",
  type: VoteSize.XXL,
  format: "image",
  status: "active",
  url: "https://placehold.co/420x480/orange/white",
  description: "You can share some of your love with me. ❤️ Byu it for me pls ",
  votes: 100,
  price: 9.99,
  priceRu: 9.99,
  user: extendedUser,
};
