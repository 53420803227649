import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./app/app";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import {Provider} from "react-redux";
import {store} from "@app/store/store";

// import "./utils/_logger";
import TagManager from "react-gtm-module";

const isBuild = !window.location?.href?.includes("localhost");

if (isBuild) {
  TagManager.initialize({
    // @ts-ignore
    gtmId: process.env.REACT_APP_GTM_ID,
  });
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);

if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/firebase-messaging-sw.js")
      .then((registration) => {
        console.log(
          "Service Worker registered with scope:",
          registration.scope,
        );
      })
      .catch((err) => {
        console.log("Service Worker registration failed:", err);
      });
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
